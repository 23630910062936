export default [
  {
    id: "472822904",
    thumb: "g05.jpg",
    length: "02:08",
    title: "Residence G05 Preview",
    description: "Display Room Showcase"
  },
  {
    id: "432674510",
    thumb: "adele.jpg",
    length: "00:59",
    title: "Adele Bates - Interior Edition",
    description:
      "It is really important for us to create something that was timeless throughout rather than being such an on trend."
  },
  {
    id: "447802962",
    thumb: "jack.jpg",
    length: "00:56",
    title: "Jack Merlo - Landscape Edition",
    description:
      "You'll certainly have the added benefit of apartment living and the lower maintenance, but won't need to lose the nice leafy aspect that perhaps you have been used to."
  },
  {
    id: "418706245",
    thumb: "embrace.jpg",
    length: "2.35",
    title: "Embrace Architect - Architecture Edition",
    description:
      "The qualities of space, light and materials should enhance the daily lifestyle of its occupiers."
  }
];
