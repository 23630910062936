<template>
  <div class="block-title">
    Featured Videos
  </div>
  <fluid-box padding=".25rem">
    <fluid-item md>
      <card-video
        class="w-full h-full clickable"
        :image="'/video-thumbs/' + featuredVideos[0].thumb"
        :description="featuredVideos[0].description"
        :title="featuredVideos[0].title"
        :length="featuredVideos[0].length"
        @click="play(featuredVideos[0].id)"
      />
    </fluid-item>

    <fluid-item sm>
      <card-video
        class="w-full h-full clickable"
        :image="'/video-thumbs/' + featuredVideos[1].thumb"
        :description="featuredVideos[1].description"
        :title="featuredVideos[1].title"
        :length="featuredVideos[1].length"
        @click="play(featuredVideos[1].id)"
      />
    </fluid-item>
    <fluid-item sm>
      <card-video
        class="w-full h-full clickable"
        :image="'/video-thumbs/' + featuredVideos[2].thumb"
        :description="featuredVideos[2].description"
        :title="featuredVideos[2].title"
        :length="featuredVideos[2].length"
        @click="play(featuredVideos[2].id)"
      />
    </fluid-item>
  </fluid-box>
  <video-player v-model="state.playing" :id="state.id" />
</template>

<script>
import CardVideo from "@/components/CardVideo";
import videos from "../data/videos";
import VideoPlayer from "./VideoPlayer";
import { reactive } from "vue";
import FluidBox from "@/pepper/layouts/FluidBox";
import FluidItem from "@/pepper/layouts/FluidItem";
export default {
  name: "FeaturedVideos",
  components: { FluidItem, FluidBox, VideoPlayer, CardVideo },
  setup() {
    const featuredVideos = videos.slice(0, 3);
    const state = reactive({
      playing: false,
      id: null
    });
    function play(id) {
      state.playing = true;
      state.id = id;
    }
    return { featuredVideos, state, play };
  }
};
</script>

<style scoped></style>
