export default {
  /* Represents Url */
  key: "105",
  label: "Residence 105",
  photoFolder: "/photos/",
  photos: ["p2/p2-kitchen-render.jpg", "p1/p1-bath.jpg"],
  floorPlan: "105/floorplan-105.png",
  title: `Downsize without Compromise`,
  body: `
    <p>This is an opportunity for downsizers to rebalance their lives and indulge in
      a relaxed, low maintenance lifestyle. Immaculate attention to detail and
      generous living, enhanced by full height windows, overlooking a leafy
      green streetscape.
    <p>
    <br/>
    <p>
    A carefully considered, three bedroom residence, offering an abundance of
    features, to include:
    </p>
    <br/>
      <ul>
        <li>Herringbone oak flooring</li>
        <li>Smart Home Ready</li>
        <li>Escea Gas Fireplace</li>
        <li>Stone and 2pac TV Joinery</li>
        <li>V-Zug Steam and Conventional Oven</li>
        <li>Liebherr Integrated Fridge</li>
        <li>Ducted Heating & Cooling throughout</li>
        <li>King sized master and WIR</li>
        <li>2 basement car parks & storage</li>
      </ul>
    <br/>
    <p>
    The addition of tranquil gas log fireplace by Escea provides a level of comfort 
    and serenity to the living and dining space whilst the state of the art kitchen is complete with top of the line Swiss appliances creating a perfect
zone for the master chefs of the home.
    </p>
    <br/>
    <p>
    Conveniently located within Melbourne’s prestige schooling district 
    and easy reach to the surrounding amenities of Kew, Balwyn and Camberwell,
      an opportunity not to be missed.
    </p>
    <br/>
    <p>
      Call Nichole Pickering today to find out more. 
    </p>
  `,
  bath: 3,
  bed: 3,
  car: 2
};
