<template>
  <div
    class="shadow rounded bg-peach overflow-hidden flex font-Poppins p-4 items-center flex-col justify-center text-center"
  >
    <img
      src="@/assets/nichole.jpg"
      alt="Nichole"
      class="rounded-full w-28 m-4 border border-gray-400 text-center"
    />
    <div class=" text-lg font-caslon">Nichole Pickering</div>
    <div class="m-2 text-sm">Mobile:<br />0448 49 48 48</div>
    <div class="m-2 text-sm">Email:<br />Nichole@Hive.Properties</div>
    <br />
    <div
      class="btn-light bg-opacity-60 bg-white w-full m-4 mt-2 text-center cursor-pointer"
      @click="scrollToReg"
    >
      Request information kit
    </div>
  </div>
</template>

<script>
import { scrollToReg } from "@/components/CardRegForm.vue";
export default {
  name: "CardReg",
  setup() {
    return { scrollToReg };
  }
};
</script>

<style scoped></style>
