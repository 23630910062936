export default {
  key: "p01",
  label: "Penthouse 1",
  photoFolder: "/photos/p1/",
  photos: ["p1-kitchen.jpg", "p1living.jpg", "p1-bed.jpg", "p1-bath.jpg"],
  title: `Penthouse Living with Treetop Views`,
  floorPlan: "floorplan-201.png",
  features: `
Full Control4 Smarthome Package
Escea Gas Fire Place 
Marble Benchtops

Triple Basement Garage 
    `,
  body: `
<p>Designer living with treetop views, atop a luxury, 
boutique building recognised for its excellence by the UDIA.
</p><br/>

<p>
Come home to 5 star quality from the moment you step inside, a pleasing activation of the senses
as you are greeted by the warm welcome of hydronic underfloor heating. Be captivated by the vast
2.9m ceilings and the immaculate attention to detail within, complete with Control 4 automation to
ensure that all of your customised creature comforts await, with a touch of a button.

</p><br/>
<p>
Showcasing an abundance of natural light and open plan living zones that flow seamlessly through
the double glazed French doors, to the extensive wrap around terrace.
</p><br/>
<p>
This generously appointed residence boasts a list of enviable features that need to be seen to be
believed.
</p><br/>

<p>
Expressions of interest to view by private inspection, prior to Grand Opening are now available.
Call Nichole Pickering today to find out more. 
</p>
  `,
  bath: 3,
  bed: 3,
  car: 3
};
