<template>
  <GoogleMap
    api-key="AIzaSyDst9H0uJk_GNO5B40B3BnB5Fv3ai72y5w"
    :styles="styles"
    :center="center"
    class="card overflow-hidden"
    :zoom="16"
  >
    <Marker
      class="marker"
      :options="{
        position: center,
        icon: { url: '/pin.png', scaledSize: scaledSize }
      }"
    />
  </GoogleMap>
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";
import { useWindowSize } from "@vueuse/core";
import { computed } from "vue";

export default {
  name: "GoogleMapsPanel",
  components: { GoogleMap, Marker },
  setup() {
    const styles = [
      {
        featureType: "administrative",
        elementType: "all",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [{ color: "#444444" }]
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [{ color: "#f2f2f2" }]
      },
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [{ saturation: "-100" }, { lightness: "57" }]
      },
      {
        featureType: "poi",
        elementType: "geometry.stroke",
        stylers: [{ lightness: "1" }]
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [{ saturation: -100 }, { lightness: 45 }]
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{ visibility: "simplified" }]
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ visibility: "on" }]
      },
      {
        featureType: "transit",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "transit",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "transit",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "off" }, { color: "#484848" }]
      },
      {
        featureType: "transit",
        elementType: "labels.text.stroke",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "on" }]
      },
      {
        featureType: "transit.station.bus",
        elementType: "all",
        stylers: [{ visibility: "on" }]
      },
      {
        featureType: "transit.station.bus",
        elementType: "labels.text.fill",
        stylers: [
          { saturation: "0" },
          { lightness: "0" },
          { gamma: "1.00" },
          { weight: "1" }
        ]
      },
      {
        featureType: "transit.station.bus",
        elementType: "labels.icon",
        stylers: [{ saturation: "-100" }, { weight: "1" }, { lightness: "0" }]
      },
      {
        featureType: "transit.station.rail",
        elementType: "all",
        stylers: [{ visibility: "on" }]
      },
      {
        featureType: "transit.station.rail",
        elementType: "labels.text.fill",
        stylers: [{ gamma: "1" }, { lightness: "40" }]
      },
      {
        featureType: "transit.station.rail",
        elementType: "labels.icon",
        stylers: [{ saturation: "-100" }, { lightness: "30" }]
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [{ color: "#d2d2d2" }, { visibility: "on" }]
      }
    ];
    const { height } = useWindowSize();
    const scaledSize = computed(() => {
      let size = height.value / 10;
      if (size > 100) size = 100;
      return { width: size, height: (size * 134) / 110 };
    });
    return {
      center: { lat: -37.813861780575365, lng: 145.0603786842912 },
      styles,
      scaledSize
    };
  }
};
</script>

<style scoped></style>
