<template>
  <div
    class="card flex font-Poppins transition-opacity duration-500 cursor-pointer"
    style="height:15vh;min-height: 130px"
    :style="{ opacity: mouseIn ? 1 : 0.8 }"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <hive-image
      :src="cover"
      style="width:20vh;min-width: 150px"
      class="flex-grow-0"
    />
    <div class="flex-grow p-4 relative">
      <div class="relative">
        <span class="font-bold fluid-title pr-1">{{ label }}</span>
        <badge3d-tour v-if="vr" class="sm:absolute right-0 top-0" />
      </div>
      <div class="text-sm" v-if="price">{{ price }}</div>
      <div class="italic hidden sm:block fluid-title">{{ title }}</div>
      <div class="align-baseline opacity-50">
        <icon-bed class="mr-1" /><span class="text-sm mr-2">{{ bed }}</span>
        <icon-shower class="mr-1" /><span class="text-sm mr-2"
          >{{ bath }}
        </span>
        <icon-car class="mr-1" /><span class="text-sm mr-2">{{ car }}</span>
      </div>
      <div class="absolute-full flex items-center md:items-end p-2 justify-end">
        <div class="btn-light text-xxs uppercase hidden lg:block">
          <span>View Listing</span>
          <icon-chevron right />
        </div>
        <div class="block lg:hidden">
          <icon-chevron right />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HiveImage from "../pepper/components/HiveImage";
import { ref } from "vue";
import IconShower from "../pepper/icons/IconShower";
import IconBed from "../pepper/icons/IconBed";
import IconCar from "../pepper/icons/IconCar";
import IconChevron from "@/pepper/icons/IconChevron";
import Badge3dTour from "@/components/Badge3dTour";
export default {
  name: "CardResi",
  components: {
    Badge3dTour,
    IconChevron,
    IconCar,
    IconBed,
    IconShower,
    HiveImage
  },
  props: {
    cover: { type: String, required: true },
    label: { type: String, required: true },
    title: { type: String, required: true },
    bath: { type: Number },
    bed: { type: Number },
    car: { type: Number },
    price: { type: String },
    vr: { type: String }
  },
  setup() {
    let mouseIn = ref(false);
    return {
      mouseEnter() {
        mouseIn.value = true;
      },
      mouseLeave() {
        mouseIn.value = false;
      },
      mouseIn
    };
  }
};
</script>

<style scoped></style>
