const slides = [
  {
    caption: "The Building",
    title: "Seamlessly Align with the Surrounding Tree-lined Streets",
    src: "hero.jpg"
  },
  {
    caption: "Garden Residence",
    title: "Neutral Color Palette is Complemented by Jack Merlo Landscaping",
    src: "g2/g2-kitchen.jpg"
  },
  {
    caption: "Kitchen - Signature range",
    title: "Uncompromising Attitude to Details",
    src: "p1/p1-kitchen.jpg"
  },
  {
    caption: "The Ensuite",
    title: "The Ideal Space to Refresh and Energise",
    src: "p1/p1-bath.jpg"
  },
  {
    caption: "The Bedroom",
    title: "Peaceful, Calming and Comforting",
    src: "p1/p1-bed.jpg"
  }
];
export default slides;
