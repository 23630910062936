<template>
  <center-box h-center>
    <hive-spinner :stroke="2" class="w-1/4 h-1/4" />
    <div class="capitalize text-xs mt-8">Loading...</div>
  </center-box>
</template>

<script>
import CenterBox from "@/pepper/layouts/CenterBox";
import HiveSpinner from "@/pepper/components/HiveSpinner";
export default {
  name: "Loading",
  components: { HiveSpinner, CenterBox }
};
</script>

<style scoped></style>
