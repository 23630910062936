export default {
  /* Represents Url */
  key: "g04",
  label: "Residence G04",
  photoFolder: "/photos/",
  photos: ["g2/g2-kitchen.jpg", "p2/p2-kitchen-render.jpg", "p1/p1-bath.jpg"],
  floorPlan: "g4/floorplan-g04.png",
  title: `A Rare Two Bedroom Offering`,
  body: `
    <p>Stunningly nestled in one of Melbourne’s most prestigious and leafy inner-city suburbs, 
    The Gratis is a boutique development offering of twelve residences, 
    completely unique to each other that define luxury, elegance, and unbridled sophistication.
      This grand ground floor two-bedroom abode boasts over 100m2 of internal living, featuring:
    <p>
    <br/>
      <ul>
        <li>Herringbone oak flooring</li>
        <li>Escea Gas Fireplace</li>
        <li>Stone and 2pac TV Joinery</li>
        <li>V-Zug Steam and Conventional Oven</li>
        <li>Smart home entry and future wiring</li>
        <li>Full sized laundry & ample storage throughout</li>
        <li>Award-winning Jack Merlo landscaping</li>
        <li>2 basement car parks & storage</li>
      </ul>
    <br/>
    Arrive home with a sense of calm, 
    welcomed by the serene natural stone water feature 
    or take advantage of your secured private street access, 
    entering into your very own garden sanctuary.
    </p>
    <br/>
    <p>
    Conveniently located within Melbourne’s prestige schooling district 
    and easy reach to the surrounding amenities of Kew, Balwyn and Camberwell,
      an opportunity not to be missed.
    </p>
    <br/>
    <p>
      Call Nichole Pickering today to find out more. 
    </p>
  `,
  bath: 2,
  bed: 2,
  car: 2
};
