<template>
  <menu-bar />
  <div class="w-full bg-warm-white box-border">
    <router-view />
  </div>
  <!--  <hr class="main-width" />-->
  <div class="py-6 bg-gray-300 shadow-inner">
    <object
      data="/hive-primary.svg"
      type="image/svg+xml"
      class="h-7vh opacity-70 m-auto"
      style="min-height: 55px"
    ></object>
    <object
      data="/slogan.svg"
      type="image/svg+xml"
      class="h-5vh opacity-70 m-auto my-2"
      style="min-height: 30px"
    ></object>
  </div>
</template>

<style lang="scss">
@import "app";
</style>
<script>
import MenuBar from "@/components/MenuBar";
export default {
  components: { MenuBar }
};
</script>
