<template>
  <hive-modal v-model="model" key-event>
    <icon-close
      circle
      :size="35"
      class="z-top fixed top-4 right-4 text-white clickable"
      @click="model = false"
    />
    <iframe
      class="full-screen"
      :src="src"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </hive-modal>
</template>

<script>
import IconClose from "../pepper/icons/IconClose";
import { computed } from "vue";
import HiveModal from "@/pepper/components/HiveModal";
import { useVModel } from "@vueuse/core";
export default {
  name: "VideoPlayer",
  components: { HiveModal, IconClose },
  props: { modelValue: Boolean, id: String },
  setup(props, { emit }) {
    const model = useVModel(props, "modelValue", emit);
    return {
      model,
      src: computed(() => {
        return `https://player.vimeo.com/video/${props.id}?autoplay=1`;
      })
    };
  }
};
</script>

<style scoped>
.full-screen {
  @apply w-screen h-screen fixed top-0 left-0 overflow-hidden border-none m-0 box-border;
}
</style>
