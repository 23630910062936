<template>
  <div class="w-full h-full relative">
    <div
      class="w-full h-full transition-opacity"
      :style="{
        opacity: state.status === status.INITIALIZED ? 1 : 0
      }"
    >
      <div class="block-title" id="reg-form">
        <div class="">Register your interest</div>
        <div class="italic pt-2">
          Leave your details below to review the following now;
        </div>
        <div
          class="flex space-x-4 items-center justify-center font-poppins uppercase py-2"
        >
          <div><icon-floor-plan /> <span class="text-xs">Floorplans</span></div>
          <div>
            <icon-view-list /> <span class="text-xs">Specifications</span>
          </div>
          <div>
            <icon-book-open-page /> <span class="text-xs">Brochures</span>
          </div>
        </div>
      </div>
      <form id="hive-form">
        <fluid-box padding="0.25rem">
          <fluid-item md>
            <div class="input-container">
              <div class="label">Name*:</div>
              <div class="flex-grow">
                <input class="input" name="name" v-model="state.name" />
                <div class="error" id="error-name"></div>
              </div>
            </div>
            <div class="input-container">
              <div class="label">Phone*:</div>
              <div class="flex-grow">
                <input class="input" name="phone" />
                <div class="error" id="error-phone"></div>
              </div>
            </div>
            <div class="input-container">
              <div class="label">Email*:</div>
              <div class="flex-grow">
                <input class="input" name="email" />
                <div class="error" id="error-email"></div>
              </div>
            </div>
            <div class="input-container">
              <div class="label">Postcode*:</div>
              <div class="flex-grow">
                <input class="input" name="post" />
                <div class="error" id="error-post"></div>
              </div>
            </div>
          </fluid-item>
          <fluid-item>
            <div class="input-container h-full">
              <div class="label">Message:</div>
              <div class=" flex flex-col w-full h-full">
                <textarea
                  class="input h-28 flex-grow"
                  name="message"
                ></textarea>
                <div class="error invisible"></div>
              </div>
            </div>
          </fluid-item>
        </fluid-box>
      </form>
      <div class="flex-grow-0 text-center">
        <button class="btn-gold text-sm my-2" id="submit-button">
          send request
        </button>
      </div>
    </div>
    <transition-fade>
      <loading
        v-if="state.status === status.LOADING"
        class="absolute-full text-gold-dark opacity-70"
      />
    </transition-fade>
    <transition-fade>
      <fluid-box
        v-if="state.status === status.SUCCESSFUL"
        class="absolute-full"
        padding="1rem"
      >
        <fluid-item md>
          <center-box class="text-center">
            Thank you {{ state.name }} for registering your interest. <br />We
            will get in touch with you shortly.
            <br />
            <br />Alternatively, you may contact Nichole directly via:
            <br />
            <a href="tel:0448 49 48 48">0448 49 48 48</a>
          </center-box>
        </fluid-item>
        <fluid-item>
          <center-box class="space-y-2">
            <div>Please find below Brochure, Specifications & Floor Plans.</div>
            <icon-item inline>
              <a
                href="https://brochure.thegratis.com.au/"
                target="_blank"
                class="text-sm clickable-gold"
              >
                <icon-book-open-page /> The Gratis Brochure Vol.1
                <icon-open-in-new class="text-xs" />
              </a>
            </icon-item>
            <icon-item inline class="text-sm clickable-gold">
              <a
                href="https://brochure.thegratis.com.au/spec"
                target="_blank"
                class="text-sm clickable-gold"
              >
                <icon-book-open-page /> The Gratis Brochure Vol.2 - Specs
                <icon-open-in-new class="text-xs" />
              </a>
            </icon-item>

            <icon-item
              inline
              class="text-sm clickable-gold"
              v-for="floorPlan in floorPlans"
              :key="floorPlan.label"
              @click="viewFloorPlan(floorPlan.path)"
            >
              <icon-floor-plan /> Floor Plan - {{ floorPlan.label }}
            </icon-item>
          </center-box>
        </fluid-item>
      </fluid-box>
    </transition-fade>
    <transition-fade>
      <center-box v-if="state.status === status.ERROR" class="absolute-full">
        <div class="sub-title text-red-800 opacity-50">
          Something went wrong. Please try again later.
        </div>
        <br />
        <button
          @click="state.status = status.INITIALIZED"
          class="btn-gold text-xs"
        >
          BACK
        </button>
      </center-box>
    </transition-fade>
  </div>
  <hive-simple-image-pop-up :src="floorPlanSrc" v-model="state.floorPlanView" />
</template>

<script>
import FluidBox from "@/pepper/layouts/FluidBox";
import FluidItem from "@/pepper/layouts/FluidItem";
import { onMounted, reactive, ref } from "vue";
import IconViewList from "@/pepper/icons/IconViewList";
import IconFloorPlan from "@/pepper/icons/IconFloorPlan";
import IconBookOpenPage from "@/pepper/icons/IconBookOpenPage";
import TransitionFade from "@/pepper/animation/TransitonFade";
import Loading from "@/components/Loading";
import CenterBox from "@/pepper/layouts/CenterBox";
import IconItem from "@/pepper/layouts/IconItem";
import IconOpenInNew from "@/pepper/icons/IconOpenInNew";

import listing from "@/data/listing";
import HiveSimpleImagePopUp from "@/pepper/components/HiveSimpleImagePopUp";

export default {
  name: "CardRegForm",
  components: {
    HiveSimpleImagePopUp,
    IconOpenInNew,
    IconItem,
    CenterBox,
    Loading,
    TransitionFade,
    IconBookOpenPage,
    IconFloorPlan,
    IconViewList,
    FluidItem,
    FluidBox
  },
  setup() {
    // console.log(listing);
    const floorPlans = [];
    for (let i = 0; i < listing.length; i++) {
      const list = listing[i];
      if (list.floorPlan) {
        floorPlans.push({
          label: list.label,
          path: list.photoFolder + list.floorPlan
        });
      }
    }
    // console.log(floorPlans);
    const status = {
      INITIALIZED: 1,
      LOADING: 2,
      SUCCESSFUL: 3,
      ERROR: 4
    };
    const state = reactive({
      status: status.INITIALIZED,
      floorPlanView: false,
      name: ""
    });

    onMounted(() => {
      window.HIVE_LEAD_FORM(
        "THEGRATIS",
        "hive-form",
        { submitButtonId: "submit-button" },
        {
          start() {
            state.status = status.LOADING;
          },
          successful() {
            state.status = status.SUCCESSFUL;
          },
          error() {
            state.status = status.ERROR;
          }
        }
      );
    });
    let floorPlanSrc = ref(null);
    function viewFloorPlan(src) {
      floorPlanSrc.value = src;
      state.floorPlanView = true;
    }

    return {
      state,
      status,
      floorPlans,
      floorPlanSrc,
      viewFloorPlan
    };
  }
};
export const scrollToReg = () => {
  document.querySelector("#reg-form").scrollIntoView({
    behavior: "smooth"
  });
};
</script>

<style scoped lang="scss">
@use "../app";
.input-container {
  @apply flex items-baseline;
}
.label {
  @apply font-poppins text-xxs text-gray-400 uppercase w-20 flex-shrink-0;
}
.input {
  @apply block w-full
    border border-opacity-50 rounded
    bg-white bg-opacity-60
    font-caslon text-lg
    p-1 px-2
    text-gray-500
    focus:outline-none focus:shadow-inner focus:bg-opacity-90 focus:ring-2 focus:outline-none focus:ring-gold focus-within:ring-opacity-40
    focus:text-gold-dark;
}
input.error {
  @apply text-red-800 border-red-800 border-opacity-50 focus:ring-red-800 focus-within:ring-opacity-40;
}
div.error {
  @apply text-xxs text-red-800 opacity-70 w-full p-1 h-8;
}
button:disabled {
  @apply pointer-events-none opacity-50;
}
</style>
