<template>
  <div class="main-view ">
    <hive-photo-slider-basic
      :base-url="baseURL"
      :slides="mainSlider"
      @slide="onSlide"
      class="hero-img"
      cover
      auto-play
    >
      <img
        src="/udia-stamp.png"
        class="absolute z-top"
        style=" bottom:1%;right:2%;width:10vw;max-width:140px;min-width:65px;transform: rotate(10deg);"
      />
    </hive-photo-slider-basic>
    <hive-text-carousel
      :content="carouselText"
      :current-label="currentSlide"
      class="w-full px-2"
    />
    <fluid-box class="pt-4" padding="0.25rem">
      <fluid-item class="space-y-2">
        <card-resi
          v-for="unit in listing"
          :key="unit"
          :cover="unit.photoFolder + unit.photos[0]"
          :title="unit.title"
          :label="unit.label"
          :bath="unit.bath"
          :bed="unit.bed"
          :car="unit.car"
          :price="unit.price"
          :vr="unit.vr"
          class="w-full"
          @click="goto(unit.key)"
        />
      </fluid-item>

      <fluid-item md max="30%" min="280px">
        <card-reg class="w-full h-full opacity-80 " />
      </fluid-item>
    </fluid-box>
    <div class="block-title">
      918-920 Burke Road, Deepdene
    </div>
    <google-maps-panel class="w-full h-40vh" />

    <featured-videos />

    <card-reg-form />
  </div>
</template>

<script>
import mainSlider from "@/data/mainSlider";
import HiveTextCarousel from "@/pepper/components/HiveTextCarousel";
import { ref } from "vue";
import CardResi from "../components/CardResi";
import CardReg from "../components/CardReg";
import listing from "@/data/listing";
import router from "@/router";
import GoogleMapsPanel from "@/components/GoogleMapsPanel";
import FeaturedVideos from "@/components/FeaturedVideos";
import CardRegForm from "@/components/CardRegForm";
import FluidBox from "@/pepper/layouts/FluidBox";
import FluidItem from "@/pepper/layouts/FluidItem";
import HivePhotoSliderBasic from "@/pepper/components/HivePhotoSliderBasic";
import { titleize, toTitleCase } from "@/pepper/utils/stringUtils";

export default {
  name: "Home",
  components: {
    HivePhotoSliderBasic,
    FluidItem,
    FluidBox,
    CardRegForm,
    FeaturedVideos,
    GoogleMapsPanel,
    CardReg,
    CardResi,
    HiveTextCarousel
  },
  setup() {
    const carouselText = [];
    for (let i = 0; i < mainSlider.length; i++) {
      const slide = mainSlider[i];
      const txt = `<div class="title">${toTitleCase(
        slide.caption
      )}</div><div class="sub-title">${titleize(slide.title)}</div>`;
      carouselText.push(txt);
    }
    const currentSlide = ref(0);
    function onSlide(index) {
      currentSlide.value = index;
    }

    return {
      mainSlider,
      baseURL: "/photos/",
      onSlide,
      carouselText,
      currentSlide,
      center: { lat: -37.813861780575365, lng: 145.0603786842912 },
      listing,
      goto(param) {
        router.push({ name: "listing", params: { listingKey: param } });
      }
    };
  }
};
</script>
<style scoped></style>
