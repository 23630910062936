<template>
  <span
    class="uppercase rounded-lg bg-opacity-80 p-0.5 px-2 bg-gold-dark text-white whitespace-nowrap inline-flex items-center space-x-0.5"
    ><icon-cube outline />

    <span class="text-xxs md:text-xs">
      3D <span class="hidden lg:inline">tour</span></span
    >
  </span>
</template>

<script>
import IconCube from "@/pepper/icons/IconCube";
export default {
  name: "Badge3dTour",
  components: { IconCube }
};
</script>

<style scoped></style>
