<template>
  <div
    class="bg-white shadow fixed content-center top-0 left-0 py-2 z-navi px-2 w-full"
  >
    <div class="main-width flex flex-wrap items-center justify-between">
      <div class="text-xxs uppercase w-28">
        <icon-chevron />
        <a href="https://theGratis.com.au" v-if="isHome">landing page</a
        ><router-link to="/" v-if="!isHome">back</router-link>
      </div>
      <img src="@/assets/logo-dark.png" style="height: 3vh;min-height: 40px" />
      <div
        class="text-xxs uppercase w-28 text-right cursor-pointer"
        @click="scrollToReg"
      >
        Register <icon-chevron down />
      </div>
    </div>
  </div>
  <!--  dummy placeholder -->
  <div class="my-2" style="height: 3vh;min-height: 35px"></div>
</template>

<script>
import IconChevron from "@/pepper/icons/IconChevron";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { scrollToReg } from "@/components/CardRegForm.vue";
export default {
  name: "MenuBar",
  components: { IconChevron },
  setup() {
    const route = useRoute();
    const isHome = computed(() => {
      return route.name === "Home";
    });

    return { isHome, scrollToReg };
  }
};
</script>

<style scoped></style>
