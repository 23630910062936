export default {
  /* Represents Url */
  key: "g05",
  label: "Residence G05",
  photoFolder: "/photos/g5/",
  photos: [
    "kitchen.jpg",
    "bath.jpg",
    "bed.jpg",
    "laundry.jpg",
    "living1.jpg",
    "living2.jpg",
    "living3.jpg",
    "masterbath.jpg",
    "rob.jpg"
  ],
  floorPlan: "floorplan-g05.png",
  title: `Smart & Sophisticated Garden Home`,
  vr: "https://my.matterport.com/show/?m=DMiaFwCBTsb",
  body: `
      <p>A truly unique residence, complete with luxury Penthouse upgrades, 
      boasting timeless and elegant sensibility with immaculate attention to detail. 
      Showcasing an abundance of natural light and open plan living zones that flow seamlessly to a private garden sanctuary designed by award winning, Jack Merlo.
      </p>
      <br/> 
      <p>
      This generously appointed home exudes luxury, offering over 300m2 of functional indoor and outdoor living, featuring: 
      </p>
      <br/>
      <ul>
        <li>Herringbone Oak Flooring</li>
        <li>Control 4 Entry, Lighting, Audio, Curtains & Climate Control</li>
        <li>Hydronic heating </li>
        <li>Molteni & Co. Wardrobes </li>
        <li>Butlers Pantry </li>
        <li>Escea Gas Fireplace</li>
        <li>Custom Natual Stone Elba & 2pac TV Joinery</li>
        <li>V-Zug Steam & Conventional Oven</li>
        <li>Cocktail Bar with built in Liebherr Wine Fridge</li>
        <li>Ducted Heating & Cooling throughout</li>
        <li>Double garage within basement plus storage </li>
      </ul>
<br/>
 

<p>The king-size master is complete with motion sensor <b>Molteni</b> robes and oversized ensuite whilst Bedroom 2 can be used as a secondary master, 
boasting a stunning WIR and ensuite with access to the garden. 
</p>
<br/>

<p>
Conveniently located within Melbourne’s prestige schooling district and minutes to the retail and dining precincts of 
Kew, Balwyn and Camberwell, an opportunity not to be missed.  
</p>

<br/>
<p>
Call Nichole Pickering today to arrange a private inspection. All enquiries MUST include a phone number to receive layout information.
</p>
  `,
  bath: 3,
  bed: 3,
  car: 2
};
