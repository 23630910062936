<template>
  <div
    class="shadow rounded bg-white overflow-hidden flex flex-col font-Poppins transition-opacity duration-500"
  >
    <hive-image :src="image" :aspect-ratio="9 / 16">
      <div
        class="w-full h-full bg-black bg-opacity-20 flex items-center justify-center align-middle text-white text-2xl opacity-80 font-caslon"
      >
        <icon-play-box /> Play Video
      </div>
      <div
        class="absolute right-0 bottom-0 p-2 text-white opacity-80 z-1 text-xs"
      >
        {{ length }}
      </div>
    </hive-image>
    <div class="flex-grow p-2 mb-2">
      <div class="font-bold text-sm">{{ title }}</div>
      <div class="text-xxs sm:text-xs">{{ description }}</div>
    </div>
  </div>
</template>

<script>
import HiveImage from "@/pepper/components/HiveImage";
import IconPlayBox from "../pepper/icons/IconPlayBox";
// import { computed } from "vue";
export default {
  name: "CardVideo",
  components: { IconPlayBox, HiveImage },
  props: {
    image: { type: String, required: true },
    title: { type: String, required: true },
    description: { type: String },
    length: { type: [Number, String] }
  }
  // setup(props) {
  //   return {
  //     lengthString: computed(() => {
  //       // let raw = props.length;
  //       // const min = Math.floor(raw);
  //       // const sec = raw - min;
  //       // return min + ":" + sec.toFixed(2) * 100;
  //     })
  //   };
  // }
};
</script>

<style scoped></style>
